// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-app-js": () => import("./../src/pages/about-app.js" /* webpackChunkName: "component---src-pages-about-app-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-next-js": () => import("./../src/pages/next.js" /* webpackChunkName: "component---src-pages-next-js" */),
  "component---src-pages-policy-js": () => import("./../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

